/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import queryString from 'query-string';

export default function Onboarding() {
  const location = useLocation();
  const { plan } = queryString.parse(location.search);

  useEffect(() => {
    if (plan) {
      sessionStorage.setItem('plan', plan);
    } else {
      sessionStorage.setItem('plan', '');
    }

    navigate('/onboarding/steps/1/');
  }, []);

  return null;
}
