import React from 'react';

import SEO from 'src/components/seo';

import Onboarding from 'src/features/onboarding';

export default function OnboardingPage() {
  return (
    <>
      <SEO title="Onboarding" />

      <Onboarding />
    </>
  );
}
